.GroceryListFilter {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*flex-direction: row;*/
    /*justify-content: space-around;*/
    /*padding: 5px;*/

    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
}

.GroceryListFilterHeader{
    background-color: darkgray;
}

.GroceryListItemCollection{
    display: flex;
    justify-content: center;
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
}

.GroceryListFilterItem {
    display: flex;
    justify-content: flex-start;
    color: white;
    font-size: 1.2em;
}

.GroceryListFilter label{
    margin: 3px 0px 3px 10px;
}

.btnCloseGroceryListFilter{
    display: flex;
    align-self: center;
    margin-top: 20px;
}

.hide {
    display: none;
}
