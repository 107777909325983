header{
    background-color: #eeeeee;
}

nav{
    display: flex;
    justify-content: flex-end;
}

nav ul {
    list-style-type: none;
    display: flex;
}

nav ul li {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}

nav ul li a{
    display: flex;
}

.login-icon{
    display: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: lightgrey;
}

.verified {
    background-color: lightgreen;
}

.rejected {
    background-color: red;
}
