h1,h2,p{
    margin: 0px;
}

.GroceryListItem {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    color: darkgray;
    border-bottom: 1px solid lightgray;
    padding: 15px 5px 15px 5px;
    font-size: 0.9em;
}

.GroceryListItem div{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: baseline;
}

.picked{
    background-color: #f3fff3;
}

.unavailable{
    background-color: #fff3f3;
}

.differentPick{
    background-color: #fffbf3;
}

.hide{
    display: none;
}

.column{
    display: flex;
    flex-direction: row;
}



.productPath {
    font-size: 0.7em;
    color: white;
    font-weight: bold;
}

.productPath p{
    margin:0px;
}

.shouldPick {
    min-width: 10px;
    min-height: 10px;
    background-color: #3b9fe2;
    border-radius: 50%;
}

.noPick{
    min-width: 10px;
    min-height: 10px;
    background-color: white;
    border-radius: 50%;
}

.productName{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 210px;
    display: block;
    margin:0px;
    margin-right: -7px;
}

.pickState{
    min-width: 20px;
}

.pickState h2{
    font-size: 16px;
    margin: 0px;
}

.pickicon--picked{
    display: flex;
    color: lightgreen;
}

.pickicon--unavailable{
    color: red;
}

.pickicon--differentPick{
    color: orange;
}

.hide{
    display: none;
}

.buttonGroup{
    display: flex;
    align-items: baseline;
}

.buttonGroup p{
    margin:0px;
}

.plusCount {
    background-color: #3b9fe2;
    color: white;
    font-weight: bold;
    padding: 3px;
    min-width: 30px;
    border-radius: 15px 0px 0px 15px;
    border: 1px solid lightgrey;
}


.minCount {
    background-color: #3b9fe2;
    color: white;
    font-weight: bold;
    min-width: 30px;
    padding: 3px;
    border-radius: 0px 15px 15px 0px;
    border: 1px solid lightgrey;
}

.productCount {
    min-width: 20px;
    padding: 3px;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
}

.trash{
    color: lightgrey;
}

.trash h2{
    margin:0px
}
