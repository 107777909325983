.GroceryList {
    display: flex;
    flex-direction: column;
}

.filterButtons{
    padding: 5px;
    display: flex;
    justify-content: flex-start;
}

button {
    background-color: #3b9fe2;
    color: white;
    border-radius: 4px;
    padding: 8px;
    border: 1px solid lightgrey;
    margin-left: 5px;
    font-weight: bold;
    font-size: 1.1em;
}

button:hover{
    background-color: #428dc2;
    cursor: pointer;
}


.titleBar{
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.titleBar select{
    margin-left: 15px;
    margin-bottom: 3px;
    font-size: 1.2em;
}

.searchBar {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.searchBar input{
    width: 80%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid lightgray;
    font-size: 1.2em;
    color: #706e6e;
}

.searchBar:hover{
    border: 1px solid lightblue;
}

.pickCounter{
    color: #61dafb;
}

.pathDivider {
    background-color: #f8f8f8;
    font-weight: bolder;
    font-size: 0.8em;
}
